site.product = {
    tileTabs: function () {
        $('body').on('click', '.tile-nav-link', function () {
            var tabId = $(this).attr('data-tab-id');

            if ($(this).hasClass('active')) {
                $(this).removeClass('active').attr('aria-selected', false);
                $('.tile-tab-content#' + tabId).removeClass('show').removeClass('active');
            } else {
                $('.tile-tab-content').removeClass('show').removeClass('active');
                $('.tile-nav-link').removeClass('active').attr('aria-selected', false);
                $(this).addClass('active').attr('aria-selected', true);
                $('.tile-tab-content#' + tabId).addClass('show').addClass('active');
            }

            return false;
        });
    }
}