site.base =
    {
        jsonErrors: function (xhr, evt, status, form) {
            var content, value, _i, _len, _ref, $this, contentWrapper;
            $this = form;
            contentWrapper = $this.find('.json-error-wrapper');
            content = contentWrapper.find('#errors');
            content.find('ul').empty();
            _ref = $.parseJSON(xhr.responseText).errors;
            // Append errors to list on page
            for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                value = _ref[_i];
                content.show().find('ul').append('<li><i class="fa fa-times-circle"></i>' + value + '</li>');
            }
            contentWrapper.show();
        },

        contactMessage: function () {
            $('body').on('submit', '#new_contact_message', function () {
                var url = $(this).attr('action'),
                    $this = $(this);
                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $(this).serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $this[0].submit();
                        },
                        error: function (xhr, evt, status) {
                            site.base.jsonErrors(xhr, evt, status, $this);
                            $([document.documentElement, document.body]).animate({
                                scrollTop: $("#contact").offset().top
                            }, 1000);
                        }
                    });
                return false;
            })
        },

        b2bContactMessage: function () {
            $('body').on('submit', '#new_b2b_contact_message', function () {
                var url = $(this).attr('action'),
                    $this = $(this);
                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $(this).serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $this[0].submit();
                        },
                        error: function (xhr, evt, status) {
                            site.base.jsonErrors(xhr, evt, status, $this);
                            $([document.documentElement, document.body]).animate({
                                scrollTop: $("#b2b_section").offset().top
                            }, 1000);
                        }
                    });
                return false;
            })
        },

        redCircleLink: function () {
            $('body').on('click', '.red-purchase-circle', function () {
                var shopUrl = $(this).attr('data-shop-url');
                window.location.href = shopUrl;
            })
        },

        changeLocale: function () {
            $('#country-selector').on('change', function () {
                window.location = site.base.updateQueryStringParameter(window.location.href, 'locale', this.value);
            });
        },

        updateQueryStringParameter: function (uri, key, value) {
            var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
            var separator = uri.indexOf('?') !== -1 ? "&" : "?";
            if (uri.match(re)) {
                return uri.replace(re, '$1' + key + "=" + value + '$2');
            } else {
                return uri + separator + key + "=" + value;
            }
        },

        initCookies: function () {
            if (localStorage.getItem('cookieToastState') != 'shown') {
                $('#cookieToast').toast('show');
                localStorage.setItem('cookieToastState', 'shown')
            }
        },

        pauseCycleCarousel: function () {
            $(window).on("scroll", function () {
                var y_scroll_pos = window.pageYOffset;

                var extrainfo = document.getElementById("heroCarousel");

                if (typeof extrainfo != "undefined" && extrainfo != null) {
                    var scroll_pos_test = $("#heroCarousel").offset().top;
                } else {
                    var scroll_pos_test = 500; // set to whatever you want it to be
                }

                if (y_scroll_pos > scroll_pos_test) {
                    $("#heroCarousel").carousel("pause");
                } else {
                    $("#heroCarousel").carousel("cycle");
                }
            });
        },

        initDiscountPopup: function(discountId)
        {
            if (localStorage.getItem('popupDiscountState_' + discountId) != 'shown') {
                timeout = setTimeout(function () {
                    $('#newsletter-modal').modal('show');
                    localStorage.setItem('popupDiscountState_' + discountId, 'shown');
                    clearTimeout(timeout);
                }, 15000);
            }
        },

        initCustomerGiftPopup: function()
        {
            if (localStorage.getItem('popupCustomerGift') != 'shown') {
                timeout = setTimeout(function () {
                    $('#customer-gift-modal').modal('show');
                    localStorage.setItem('popupCustomerGift', 'shown');
                    clearTimeout(timeout);
                }, 15000);
            }
        }
    }